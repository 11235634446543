$(document).ready(function () {
    $('.select2').each(function () {
        $(this).select2({
            placeholder: $(this).attr('placeholder')
        })
    });

    function matchStart(params, data) {
        params.term = params.term || '';
        if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
            return data;
        }
        return false;
    }

    $('.select2.js-nationality-field').select2({
        matcher: function(params, data) {
            return matchStart(params, data);
        }
    });

    $('input.datepicker').datepicker({
        format: 'yyyy-mm-dd',
        language: 'en',
        autoclose: true,
    });

    $('input.datepicker-birthday').datepicker({
        format: 'yyyy-mm-dd',
        language: 'en',
        autoclose: true,
        startView: "decades",
        startDate: '1910-01-01',
        endDate: new Date(),
    });

    $('form').each(function() {
        $(this).validate({
            errorClass: "has-error help-block",
            validClass: "", //has-success to make it green when okay
            errorElement: "span",
            highlight: function(element, errorClass, validClass) {
                $(element).closest('.form-group').removeClass(validClass).addClass(errorClass).find('span.' + errorClass).addClass('help-block');
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).closest('.form-group').removeClass(errorClass).addClass(validClass);
            }
        });
    });

    $(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

    $('.favorite').on('ajax:success', function(event, data, status, xhr) {
        if(data) {
            if(data.favorite === 'favorited') {
                $(this)
                    .removeClass('not-favorited')
                    .addClass('favorited');
            } else {
                $(this)
                    .removeClass('favorited')
                    .addClass('not-favorited');
            }
        }
    });
    
    $( window ).scroll(function() {
		if($(document).scrollTop() > 100) {
			$('.page-header').addClass('page-header--scrolled');
		} else {
			$('.page-header').removeClass('page-header--scrolled');
		}
	});
	
	$(function () {
		$('[data-toggle="tooltip"]').tooltip()
	})
    
});